.appContainer {
  background: white;
}
.appContainer .appContentContainer {
  margin-left: 5rem;
  margin-right: 5rem;
}

.navBarY {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-image: url();
  margin-bottom: 20px;
}
.navBarY .brandY {
  color: #308A7B !important;
  font-size: 1.4rem;
}
.navBarY .facebookSvg {
  height: 40px;
  width: 50px;
}

.carouselContainer {
  padding: 0px !important;
  margin: 20px;
}
.carouselContainer .carouselPic {
  max-height: 35rem;
}

.oakSvg {
  height: 70px;
  width: 70px;
  margin: 0.5rem;
}

@media screen and (max-width: 498px) {
  #appCellContainer {
    margin: 0;
    padding: 0;
  }

  .oakSvg {
    height: 0px;
    width: 0px;
    margin: 0rem;
  }

  .brandY {
    font-size: 1rem !important;
  }

  .carouselPic {
    min-height: 16rem;
  }

  .carouselTitleY {
    font-size: 1.5rem;
  }

  .carouselTextY {
    font-size: 0.8rem;
  }
}
.navBarY:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.jumbotronClassY {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 30px 0px 0px;
  background-image: url("./images/polaroid.png");
}
.jumbotronClassY .jumboTextY {
  color: #308A7B;
  font-size: 2rem;
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

.officeLocationsAndHours {
  margin: 20px;
  padding: 20px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background: #f8f9fa;
}
.officeLocationsAndHours .dayOfWeek {
  color: #308A7B;
}

.meetTheDoctorContainer {
  margin: 20px;
  padding: 40px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background: #f8f9fa;
}
.meetTheDoctorContainer .meetTheDoctorImg {
  margin: 0 auto;
}
.meetTheDoctorContainer .meetTheDoctorText {
  padding: 40px;
}

.meetTheStaffContainer {
  background: #f8f9fa;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.mediaTest {
  background: pink;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.mediaTest img {
  height: 40px;
  width: 40px;
}

.tripleCardContainerFinalY {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.tripleCardContainerFinalY .YorgiCards {
  padding: 0px;
}
.tripleCardContainerFinalY .tripleCardLinkY {
  text-decoration: none;
  color: black;
}
.tripleCardContainerFinalY .tripleCardLinkY .cardItemY {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  height: 100%;
  background: #f8f9fa;
}
.tripleCardContainerFinalY .tripleCardLinkY .cardItemY .cardContentY .cardContentBottomY {
  margin: 10px 20px 10px 20px;
}
.tripleCardContainerFinalY .tripleCardLinkY .cardItemY .cardContentY .cardContentBottomY .cardTitleY {
  margin: 5px 0 5px 0;
  padding: 5px 0 5px 0;
}
.tripleCardContainerFinalY .tripleCardLinkY .cardItemY .cardContentY .cardContentBottomY .cardTextY {
  margin: 5px 0 5px 0;
  padding: 5px 0 5px 0;
}
.tripleCardContainerFinalY .tripleCardLinkY .cardItemY .cardContentY .cardContentBottomY .cardTextY .officeTitle {
  text-decoration: underline;
}
.tripleCardContainerFinalY .tripleCardLinkY .cardItemY:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.9);
}

.iconDues {
  margin: 0 auto;
  width: 50%;
  margin-top: 60px;
  color: white;
}
.iconDues a {
  color: inherit;
}

.emailContainer {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  padding: 30px;
  margin: auto;
  margin-top: 30px;
  background-color: #f8f9fa;
}
.emailContainer .emailForm {
  margin: auto;
}
.emailContainer .emailForm #emailSubmit {
  color: white;
  background-color: #308A7B;
}

.emailFormInLocationsPg {
  float: right;
  vertical-align: middle;
}

