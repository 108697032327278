

$color-primary: white;
$color-secondary: #D3EEE8;
$color-tertiary: #308A7B;
$my-off-white: #f8f9fa;


.appContainer{
    background: $color-primary;
    

    .appContentContainer{
      margin-left: 5rem;
      margin-right: 5rem;
    
    }
  
}
.navBarY{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
    background-image: url();
    margin-bottom: 20px;
    
    
    .brandY{
        color: $color-tertiary !important;
        font-size: 1.4rem;
 
       
       
    }
    
    .facebookSvg{
        height: 40px;
        width: 50px;
        
        
        
    }
   
   
}

.carouselContainer{

  padding: 0px !important;
  margin: 0 auto;
  margin-bottom: 20px;

  
  .carouselPic{
    max-height: 35rem;
    
  }
  
 
}

.oakSvg{
  height: 70px;
  width:70px;
  margin: 0.5rem;
 
} 

@media screen and (max-width: 498px){
  #appCellContainer{
    margin: 0;
    padding: 0;
  }
  .oakSvg{
    height: 0px;
    width:0px;
    margin: 0rem;
   
  }

  .brandY{
    font-size: 1rem !important;
  }

  .carouselPic{
    min-height: 16rem;
    
  }
  .carouselTitleY{
    font-size: 1.5rem;
  }
  .carouselTextY{
    font-size: 0.8rem;
  }
}

.navBarY:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); 
}




.jumbotronClassY{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
    margin: 30px 0px 0px;
  
    background-image: url('./images/polaroid.png');
    .jumboTextY{
        color: $color-tertiary;
        font-size: 2rem;
    }
}







img {
  display: block;
  width: 100%;
  height: 100%;
}










.officeLocationsAndHours{
  margin: 20px;
  padding: 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  background: $my-off-white;
  .dayOfWeek{
    color: $color-tertiary;
  }
}
  

.meetTheDoctorContainer{

  margin: 20px;
  padding: 40px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  background: $my-off-white;
  .meetTheDoctorImg{
    margin: 0 auto;
  }
  .meetTheDoctorText{
    padding:40px;
  }
  
}

.meetTheStaffContainer{
  background: $my-off-white;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}




.mediaTest{
  background: pink;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  img{
    height: 40px;
    width: 40px;
  }
}


.tripleCardContainerFinalY{
 
 display: flex;
 flex-wrap: wrap;
 justify-content: space-between;

 .YorgiCards{
   padding: 0px;
 }

  .tripleCardLinkY{
     text-decoration: none;
     color: black;
    
   
     
    .cardItemY{
      
      
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
      
      height: 100%;
      background: $my-off-white;

      .cardContentY{
      
        
        .cardContentBottomY{
          margin:  10px 20px 10px 20px;
          
         .cardTitleY{
           
           margin: 5px 0 5px 0;
           padding: 5px 0 5px 0;
         }
         .cardTextY{
          margin: 5px 0 5px 0;
          padding: 5px 0 5px 0;
          .officeTitle{
            text-decoration: underline;
          }
          
         }
        }
      }
    }
    .cardItemY:hover{
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.9);
     
    }

  }
  
  
 

}

.iconDues{
  margin: 0 auto;
  width: 50%;
  margin-top: 60px;
  
  color:white;
  a{color: inherit;}

  
}


.emailContainer{
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
padding:30px;
margin:auto;
margin-top: 30px;
background-color: $my-off-white;
   .emailForm{
    
    margin:auto;
    #emailSubmit{
      color:white;
      background-color: $color-tertiary;
    }
   }

  
  
}

.emailFormInLocationsPg{
  
  float: right;
  vertical-align: middle;

}



